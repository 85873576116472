<template>
  <div>
    <form class="auth-form" @submit.prevent="register()">
      <base-logo-wave class="text-center auth-logo" />

      <h2>{{ $t('auth.sign_up.title') }}</h2>

      <base-input
        v-model="signUpData.email"
        type="email"
        :placeholder="$t('auth.sign_up.email_placeholder')"
        required
        autofocus
      />

      <base-input
        v-model="signUpData.password"
        type="password"
        :placeholder="$t('auth.sign_up.password_placeholder')"
        required
      />

      <base-input
        v-model="signUpData.password_confirmation"
        type="password"
        :placeholder="$t('auth.sign_up.repeat_password_placeholder')"
        required
      />

      <base-checkbox class="mb-custom" v-model="signUpData.signed_to_newsletter">
        {{ $t('auth.sign_up.news_and_updates_checkbox') }}
      </base-checkbox>
      <base-checkbox
        v-model="signUpData.agreed_to_terms_and_conditions"
      >
        {{ $t('auth.sign_up.i_agree_with') }}
        &nbsp;<a :href="env.terms_and_conditions_url" target="_blank">{{ $t('auth.sign_up.terms_and_conditions') }}</a>
        {{ $t('auth.sign_up.and') }}
        &nbsp;<a :href="env.privacy_policy_url" target="_blank">{{ $t('auth.sign_up.privacy_policy') }}</a>
      </base-checkbox>

      <base-button
        :disabled="signUpNotValid"
        type="submit"
        class="btn-block btn-lg"
      >
        {{ $t('auth.sign_up.sign_up_btn') }}
      </base-button>

      <p class="bottom-text text-center">
        {{ $t('auth.sign_up.already_have_an_account') }}
        &nbsp;<router-link :to="{ name: 'login' }">{{ $t('auth.sign_up.log_in') }}</router-link>
      </p>
    </form>
  </div>
</template>

<script>
import BaseLogoWave from '../../components/BaseLogoWave.vue'
import BaseInput from '../../components/BaseInput.vue'
import BaseCheckbox from '../../components/BaseCheckbox.vue'
import BaseButton from '../../components/BaseButton.vue'

export default {
  data () {
    return {
      signUpData: {
        email: '',
        password: '',
        password_confirmation: '',
        signed_to_newsletter: false,
        agreed_to_terms_and_conditions: false
      },
      env: {
        terms_and_conditions_url: process.env.VUE_APP_TERMS_AND_CONDITIONS_URL,
        privacy_policy_url: process.env.VUE_APP_PRIVACY_POLICY_URL
      }
    }
  },
  components: {
    BaseLogoWave,
    BaseInput,
    BaseCheckbox,
    BaseButton
  },
  computed: {
    signUpNotValid() {
      return !this.signUpData.agreed_to_terms_and_conditions || this.signUpData.email.includes('+');
    }
  },
  methods: {
    async register () {
      await this.$recaptchaLoaded()
      this.$Progress.start()

      this.$recaptcha('sign_up').then(token => {
        this.$auth.register({
          data: this.signUpData,
          autoLogin: false,
          rememberMe: false
        }).then(() => {
          this.$Progress.finish()
          this.$router.push({ name: 'thank-you-for-subscribing', params: { email: this.signUpData.email } })
        }, (error) => {
          this.$Progress.finish()

          this.$notify({
            type: 'error',
            text: this.devise().formatResponse(error.response.data) || this.$t('common.unknown_error')
          })
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
h2 {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--cool-grey);
  margin-bottom: 20px;
}

.mb-custom {
  margin-bottom: 16px;
}
</style>
